<script>
import api from '@/command/api'
import TemplateHeaderTable from '../../../components/TemplateHeaderTable'
import { getAction } from '@/command/netTool'
import orderConfig from '@/orderConfig'
import moment from 'moment'
import { orderStateList, bedTypeList } from '@/utils/textFile'
export default {
  name: 'facility',
  data() {
    return {
      ...api.command.getState(),
    }
  },
  mounted() {
    api.command.getList.call(this, {
      url: '/home/order/page',
      current: 1,
    })
  },
  methods: {
    getHeader() {
      return [
        {
          name: '单号',
          type: 'input',
          key: 'orderSn',
          placeholder: '请输入单号',
        },
        {
          name: '出售商品',
          type: 'input',
          key: 'productName',
        },
        // {
        //   name: '入住时间',
        //   type: 'datePicker',
        //   key: 'checkInTime',
        //   placeholder: '请输入入住时间',
        //   formatdate: function(data) {
        //     return moment(data).format('YYYY-MM-DD HH:mm:ss')
        //   }
        // },
        {
          name: '入住时间',
          type: 'rangePicker',
          keys: ['checkInTimeStart', 'leaveTimeEnd'],
        },
        {
          name: '店铺名称',
          type: 'input',
          key: 'shopName',
        },
        {
          name: '购买人',
          type: 'input',
          key: 'buyName',
        },
        {
          name: '电话',
          type: 'input',
          key: 'buyPhone',
          placeholder: '请输入购买人电话',
        },
      ]
    },
    // 获取选中订单详情
    getDetailData(id) {
      return new Promise((resolve, reject) => {
        getAction(`/api/home/order/detail?id=${id}`)
          .then((result) => {
            resolve(result.data)
          })
          .catch((e) => reject && reject(e))
      })
    },
    // 退款事件
    onRefund({ records, item }) {
      let that = this
      // type手动传参 告诉当前订单类型
      orderConfig.refund({
        data: records,
        params: { data: { ...records, type: 'facility' } },
        executeFun: [orderConfig.table, orderConfig.checkInfo, orderConfig.base, orderConfig.afterProcessing],
        success({ data, setHidden }) {
          api.order.refund
            .call(that, {
              url: '/home/order/refund',
              params: { ...item, orderId: item.orderId, ...data },
            })
            .then(setHidden)
        },
      })
    },
    // 获取选中订单发票详情
    getInvoiceDetailData(id) {
      return new Promise((resolve, reject) => {
        getAction(`/farmOrderInvoice/queryDetailByOrderId?orderId=${id}`)
          .then((result) => {
            resolve(result.data)
          })
          .catch((e) => reject && reject(e))
      })
    },
    //开票信息
    onInvoice(records) {
      const ext = [
        // 基本信息
        orderConfig.invoices,
        // 接收信息
        orderConfig.invoicesAccept,
      ]
      this.getInvoiceDetailData(records.id).then((e) => {
        orderConfig.open({
          title: '开票信息',
          data: e,
          params: { data: e },
          executeFun: ext,
        })
      })
    },
    onClose(data) {
      orderConfig.close({
        data,
        params: {
          data,
        },
        executeFun: [
          // 表格数据
          orderConfig.table,
          // 入住信息
          orderConfig.checkInfo,
          // // 基础信息
          // orderConfig.base,
          // 记录
          orderConfig.record,
        ],
        success: ({ setHidden }) => {
          api.order.close
            .call(this, {
              url: '/common/order/close',
              params: { id: data.id },
            })
            .then(setHidden)
        },
      })
    },
    getColumns() {
      return [
        {
          dataIndex: 'orderSn',
          title: '单号',
          isId: true,
          align: 'left',
          sorter: (a, b) => a.orderSn - b.orderSn,
        },
        {
          dataIndex: 'productName',
          title: '出售商品',
          align: 'left',
          customRender: (text, records) => {
            return (
              <div>
                {records.orderItemList.map((e) => (
                  <div class="product-order-item">
                    <img src={e.productPic} />
                    <div>{e.productName}</div>
                  </div>
                ))}
              </div>
            )
          },
        },
        {
          dataIndex: 'bedType',
          title: '床类型',
          align: 'left',
          filters: bedTypeList,
          filterMultiple: false,
          customRender: (text, records) => {
            return (
              <div>
                {records.orderItemList.map((e) => {
                  return <div>{(bedTypeList[Number(e.bedType)] || { text: '' }).text}</div>
                })}
              </div>
            )
          },
          onExport: (records) => (bedTypeList[Number(records)] || { text: '' }).text,
        },
        {
          dataIndex: 'shopName',
          title: '店铺名称',
          align: 'left',
        },
        {
          dataIndex: 'productQuantity',
          title: '数量',
          align: 'left',
          customRender: (text, records) => {
            return (
              <div>
                {records.orderItemList.map((e) => {
                  return <div>{e.productQuantity}</div>
                })}
              </div>
            )
          },
        },
        {
          dataIndex: 'payAmount',
          title: '实付金额',
          align: 'left',
          customRender: (text, records) => {
            if (records.refundAmount) {
              return (
                <div>
                  <span>{records.payAmount.toFixed(2)}</span>
                  <span>（{records.refundAmount.toFixed(2)} 已退）</span>
                </div>
              )
            } else {
              return <div>{records.payAmount.toFixed(2)}</div>
            }
          },
        },
        {
          dataIndex: 'checkInTime',
          title: '入住时间',
          align: 'left',
          customRender: function customRender(text, records) {
            let a = moment(records.orderItemList[0].checkInTime).unix()
            let b = moment(records.orderItemList[0].leaveTime).unix()
            let day = (b - a) / (24 * 60 * 60)

            let txt = records.orderItemList ? moment(records.orderItemList[0].checkInTime).format('YYYY/MM/DD') : ''
            return (
              <div style={{ fontSize: '12px' }}>
                {txt}（{day}天）
              </div>
            )
          },
        },
        {
          dataIndex: 'status',
          title: '订单状态',
          filters: orderStateList,
          filterMultiple: false,
          align: 'left',
          customRender: (text) => (orderStateList.find((e) => e.value == text) || { text: '' }).text,
          onExport: (text) => (orderStateList.find((e) => e.value == text) || { text: '' }).text,
        },

        {
          dataIndex: 'buyName',
          title: '购买人',
          align: 'left',
        },
        {
          dataIndex: 'buyPhone',
          title: '电话',
          align: 'left',
          customRender: function customRender(text, records) {
            return <div style={{ fontSize: '12px' }}>{text}</div>
          },
        },
        {
          dataIndex: 'action',
          title: '操作',
          type: 'buttonGroup',
          align: 'center',
          typeData: ({ records }) => {
            return [
              {
                name: '开票信息',
                display:  records.invoiceStatus && records.invoiceStatus != 0,
                onClick: () => this.onInvoice(records),
              },
              {
                name: '主动退款',
                display:
                  records.orderItemList[0].returnStatus == '0' &&
                  records.status != '6' &&
                  records.status != '4' &&
                  records.status != '5' &&
                  records.status != '0',
                onClick: () =>
                  this.getDetailData(records.id).then((e) =>
                    this.onRefund({
                      item: records.orderItemList[0],
                      records: e,
                    })
                  ),
              },
              //   {
              //     name: '关闭',
              //     display: records.status == '0',
              //     onClick: () => this.getDetailData(records.id).then(this.onClose)
              //   }
            ].filter((e) => e.display)
          },
        },
      ]
    },
    // 批量关闭
    onBatchDelete(keys) {
      return api.order.batchClose.call(this, {
        url: '/common/order/closeBatch',
        params: { idList: keys.map((e) => e.id) },
      })
    },
    getButton() {
      return [
        {
          viewType: 'menu',
          name: '更多操作',
          isBatch: true,
          children: [
            {
              name: '批量关闭',
              onClick: this.onBatchDelete,
            },
          ],
        },
      ]
    },

    onRowSelect(records) {
      this.getDetailData(records.id).then((detail) => {
        orderConfig.completed({
          data: detail,
          params: {
            data: detail,
          },
          executeFun: [
            // 表格数据
            orderConfig.table,
            // 退款信息
            orderConfig.retuenInfo,
            // 入住信息
            orderConfig.checkInfo,
            // 基础信息
            orderConfig.base,
            // 记录
            orderConfig.record,
          ],
        })
      })
    },
  },
  render() {
    return (
      <TemplateHeaderTable
        headerData={this.getHeader()}
        tableColumns={this.getColumns()}
        button={this.getButton()}
        records={this.records}
        onSelectRow={this.onRowSelect}
      />
    )
  },
}
</script>

<style lang="less">
.product-order-item {
  display: flex;
  align-items: flex-start;
  &:not(:last-child) {
    margin-bottom: 30px;
  }
  img {
    width: 30px;
    height: 30px;
    margin-right: 10px;
    border-radius: 5px;
    object-fit: cover;
  }
}
.product-number {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  div {
    height: 30px;
    &:not(:last-child) {
      margin-bottom: 30px;
    }
  }
}
</style>
